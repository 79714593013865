<template>
    <div style="margin-bottom: 100px;">
        <v-container fluid style="margin-bottom: 0px !important;">
            <v-row>
                <v-col cols="12">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>       
        </v-container>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <v-card class="ma-0 pb-0 mb-0 mt-0" elevation="1" style="border-radius: 0px !important;">
            <v-card-text class="pb-2 mb-0">                
                <v-expand-transition>                    
                    <v-container fluid style="margin-bottom: 10px !important;">
                        <v-row>
                            <v-col class="col-6 pa-0 pr-1" md="2" lg="1">
                                <span class="mb-0 font-12">New Customer <span class="red--text"><strong>* </strong></span></span>
                                <v-switch class="ma-2 pa-0" v-model="newCustSwitchModel" color="primary" value="Y" hide-details @change="hideSearhCustomer()"></v-switch>
                            </v-col>
                            <v-col class="col-12 pa-0 pr-1" md="4">
                                <span class="mb-0 font-12">Customer </span>
                                <v-autocomplete
                                    v-model="findCustomerModel"
                                    :items="findCustomerLists"
                                    item-value="CustomerId"
                                    item-text="NamaCustomer"
                                    :search-input.sync="search"
                                    cache-items
                                    outlined
                                    dense
                                    class="ma-0 pa-0"
                                    hide-no-data
                                    hide-details
                                    clearable
                                    :disabled="showFindCustomerDisable"
                                    @change="(event) => onChangeCustomer(event)"></v-autocomplete>
                            </v-col>
                            <v-col class="col-12 pa-0 pr-1" md="2">
                                <span class="mb-0 font-12">Customer Name <span class="red--text"><strong>* </strong></span></span>
                                <v-text-field class="ma-0 pa-0" v-model="customerNameModel" clearable outlined dense :disabled="customerNameDisable" hide-details></v-text-field>
                            </v-col>                            
                            <v-col class="col-6 pa-0 pr-1" md="2">
                                <span class="mb-0 font-12">Province <span class="red--text"><strong>* </strong></span></span>
                                <v-text-field class="ma-0 pa-0" v-model="customerProvinceModel" clearable outlined dense :disabled="customerProvinceDisable" hide-details></v-text-field>
                            </v-col>
                            <v-col class="col-6 pa-0 pr-1" md="3">
                                <span class="mb-0 font-12">City <span class="red--text"><strong>* </strong></span></span>
                                <v-text-field class="ma-0 pa-0" v-model="customerCityModel" clearable outlined dense :disabled="customerCityDisable" hide-details></v-text-field>
                            </v-col>
                            <v-col class="col-12 pa-0 pr-1" md="12">
                                <span class="mb-0 font-12">Address <span class="red--text"><strong>* </strong></span></span>
                                <v-text-field class="ma-0 pa-0" v-model="customerAddressModel" clearable outlined dense :disabled="customerAddressDisable" hide-details></v-text-field>
                            </v-col>
                            <!-- <v-col class="col-12 pa-0 pr-1" md="12">
                                <span class="mb-0 font-12">Car <span class="red--text"><strong>* </strong></span></span>
                                <v-checkbox class="mr-3" dense v-model="checkboxCar" :label="'Company Vehicle'" ></v-checkbox>
                            </v-col> -->
                            <v-col class="col-12 pa-0 pr-1" md="2">
                                <span class="mb-0 font-12">Vehicle </span>
                                <v-autocomplete
                                    v-model="checkboxCar"
                                    :items="checkboxCars"
                                    item-value="id"
                                    item-text="desc"
                                    cache-items
                                    outlined
                                    dense
                                    class="ma-0 pa-0"
                                    hide-no-data
                                    hide-details>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-expand-transition>
            </v-card-text>
            <v-tabs color="primary" class="ml-5" height="32">
                <v-tab>
                    Activity
                </v-tab>
            </v-tabs>
        </v-card>
        <v-container style="margin-bottom: 0px !important;">
            <v-row>
                <v-col cols="12">
                    <v-card flat>
                        <v-card-text>
                            <v-container style="margin-bottom: 0px !important;">
                                <v-row>
                                    <!-- <v-col class="col-12" md="12">
                                        <span class="mb-0 font-12">ATTENDANCE <span class="red--text"><strong>* </strong></span></span>
                                        <div class="checkbox d-flex">
                                            <v-checkbox class="mr-3" dense v-model="checkbox6Model" :label="'Absen Masuk'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkboxLeaveModel" :label="'Absen Pulang'" ></v-checkbox>
                                        </div>
                                    </v-col> -->

                                    <v-col class="col-12" md="12">
                                        <span class="mb-0 font-12">START POINT <span class="red--text"><strong>* </strong></span></span>
                                        <div>
                                            <v-radio-group v-model="checkpointModel">
                                            <div class="row">
                                                <div class="col-6">
                                                Office <span class="red--text">(Office location as default point)</span>
                                                <v-radio label="Start Point" value="OS"></v-radio>
                                                <v-radio label="End Point" value="OE"></v-radio>
                                                <a @click="checkpointModel = ''" class="mt-2" style="color: red;">Clear Choice</a>
                                                </div>
                                                <div class="col-6">
                                                User <span class="red--text">(User location as default point)</span>
                                                <v-radio label="Start Point" value="US"></v-radio>
                                                <v-radio label="End Point" value="UE"></v-radio>
                                                </div>
                                            </div>
                                            </v-radio-group>
                                            
                                        </div>
                                    </v-col>

                                    <v-col class="col-12" md="12">
                                        <span class="mb-0 font-12">ACTIVITY <span class="red--text"><strong>* </strong></span></span>
                                        <div class="checkbox d-flex">
                                            <v-checkbox class="mr-3"  dense v-model="checkbox1Model" :label="'Penawaran Produk'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox2Model" :label="'Taking Order'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox3Model" :label="'Info Program/Hadiah'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox4Model" :label="'Penagihan'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox5Model" :label="'Customer Visit/Assistensi'" ></v-checkbox>
                                        </div>
                                    </v-col>
                                    <!-- <v-col class="col-12 hidden-dekstop" md="12">
                                        <span class="mb-0 font-12">Activity </span>
                                        <div class="checkbox">
                                            <v-checkbox class="mr-3"  dense v-model="checkbox1Model" :label="'PENAWARAN PRODUCT'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox2Model" :label="'TAKING ORDER'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox3Model" :label="'INFO PROGRAM/HADIAH'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox4Model" :label="'PENAGIHAN'" ></v-checkbox>
                                            <v-checkbox class="mr-3" dense v-model="checkbox5Model" :label="'CUSTOMER VISIT'" ></v-checkbox>
                                        </div>
                                    </v-col> -->
                                    <v-col class="col-12" md="12">
                                        <span class="mb-0 font-12">Image <span class="red--text"><strong>* </strong></span></span>
                                    </v-col>
                                    <v-col class="col-6 pa-0 pr-1" md="2" lg="1" v-for="(gambar, key) in images" :key="key">
                                        <img :src="gambar.src" class="img" style="width: 100%; border-radius:6px; object-fit: contain;">
                                        <button class="btn btn-danger" v-on:click.prevent="hapus(key)" style="margin-top: 5px;padding:5px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                        </button>
                                    </v-col>
                                    <v-col class="col-6 pa-0 pr-1" md="2">
                                        <div style="padding: 40px; text-align:center; border: 2px dashed rgb(153 146 146); cursor: pointer; width:100%" @click="openModal()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-5">
                                    <v-col class="col-12" sm="12" md="12" lg="6">
                                        <v-btn class="mt-3 mb-2" color="primary" elevation="2" small rounded @click="getLocation()">Get GPS Location<v-icon right dark>mdi-google-maps</v-icon></v-btn>
                                        <div id="mapCanvas" style="height: 260px; width: 100%; border: 1px solid #213fad; border-radius: 10px;"></div>
                                        <v-row class="ma-2">
                                            <v-col class="col-12" sm="12" md="12" lg="4">
                                                <span class="mb-0 font-12">Speedometer KM <span class="red--text"><strong>* </strong></span></span>
                                                <v-text-field class="ma-0 pa-0" type="number" ref="speedoInput" @input="speedoKmModel = speedoKmModel.replace(/[^0-9]/g, '')" v-model="speedoKmModel" clearable outlined dense hide-details></v-text-field>
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12" lg="4">
                                                <span class="mb-0 font-12">Latitude <span class="red--text"><strong>* </strong></span></span>
                                                <v-text-field class="ma-0 pa-0" v-model="latitudeModel" clearable outlined dense :disabled="true" hide-details></v-text-field>
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12" lg="4">
                                                <span class="mb-0 font-12">Longitude <span class="red--text"><strong>* </strong></span></span>
                                                <v-text-field class="ma-0 pa-0" v-model="longitudeModel" clearable outlined dense :disabled="true" hide-details></v-text-field>
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12" lg="12">
                                                <span class="mb-0 font-12">Loc Address(Based on phone GPS) <span class="red--text"><strong>* </strong></span></span>
                                                <v-text-field class="ma-0 pa-0" v-model="currentLocationModel" clearable outlined dense :disabled="true" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="col-12" sm="12" md="12" lg="6">
                                        <v-btn class="mt-3 mb-2" color="primary" elevation="2" small rounded @click="getLocationPrev()" disabled>Get GPS Before Location<v-icon right dark>mdi-google-maps</v-icon></v-btn>
                                        <div id="mapCanvasPrev" style="height: 260px; width: 100%; border: 1px solid #213fad; border-radius: 10px;"></div>
                                        <v-row class="ma-2">
                                           <v-col class="col-12" sm="12" md="12" lg="6">
                                                <span class="mb-0 font-12">Speedometer KM Before</span>
                                                <v-text-field class="ma-0 pa-0" :disabled="true" v-model="speedoKmBeforeModel" clearable outlined dense hide-details></v-text-field>
                                            </v-col>
                                           <v-col class="col-12" sm="12" md="12" lg="6">
                                                <span class="mb-0 font-12">Latitude Before</span>
                                                <v-text-field class="ma-0 pa-0" v-model="latitudeBeforeModel" clearable outlined dense :disabled="true" hide-details></v-text-field>
                                            </v-col>
                                           <v-col class="col-12" sm="12" md="12" lg="12">
                                                <span class="mb-0 font-12">Longitude Before</span>
                                                <v-text-field class="ma-0 pa-0" v-model="longitudeBeforeModel" clearable outlined dense :disabled="true" hide-details></v-text-field>
                                            </v-col>
                                            <!-- <v-col class="col-6" sm="6" md="6" lg="6">
                                                <span class="mb-0 font-12">Dist. Speedometer </span>
                                                <v-text-field class="ma-0 pa-0" v-model="distanceSpeedo" clearable outlined dense :disabled="true" hide-details></v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="6" lg="6">
                                                <span class="mb-0 font-12">Dist. Current and Previous (Km based on GPS) </span>
                                                <v-text-field class="ma-0 pa-0" v-model="distance" clearable outlined dense :disabled="true" hide-details></v-text-field>
                                            </v-col> -->
                                        </v-row>
                                    </v-col>
                                    <v-col class="col-12" sm="12" md="12" lg="12">
                                        <v-textarea v-model="remarkModel" label="REMARK" clearable outlined dense></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-card class="ma-0 mt-2" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn @click="submitData" color="success" size="small" class="pb-0 mr-3" >Save</v-btn>
                            <v-btn @click="clear" color="error" variant="text" size="small" class="pb-0 mr-3">Cancel</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog
        v-model="dialog"
        max-width="600"
        persistent
        >
            <v-card>
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-0">
                                Upload Image
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-0"
                                    x-small
                                    @click="dialog = false"
                                    plain
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text >
                    <v-container fluid style="margin-bottom: 0px !important;">                       
                        <v-row >
                            <v-col class="col-12" sm="12" md="12">
                                <span class="blue-lcd mb-2 font-12">Category <span class="red--text"><strong>* </strong></span></span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="category"
                                    :items="categories"
                                    item-value="str1"
                                    :item-text="item => item.str2.trim()"
                                    class="ma-0 mt-2 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="12" md="12">
                                <span class="blue-lcd mb-2 font-12">Price</span>                                
                                <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                dense v-text-field--is-booted v-text-field--enclosed">
                                    <div class="v-input__control">
                                        <div class="v-input__slot">
                                            <div class="v-text-field__slot">
                                                <money class="" name="length" v-model="price" v-bind="money"></money>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="col-12 pr-1" md="12" v-if="image">
                            <img :src="image" class="img" style="width: 100%; border-radius:6px; object-fit: contain;">
                            </v-col>
                            <v-col class="col-12 pr-1" md="12" v-else>
                                <!-- <span class="mb-0 font-12">File Image (max 2 MB) <span class="red--text"><strong>* </strong></span></span> -->
                                <input type="file" name="image" id="image" @change="fileChange" class="form-control" accept="image/*">
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        variant="tonal"
                        @click="applyCropped()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>    
</template>

<script>
import { compact } from 'lodash'
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            breadcumbs: [
                {
                text: 'SMS Web',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Sales Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Customer Visit',
                disabled: true,
                href: '#',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            findCustomerModel: '',
            findCustomerLists: [],
            showFindCustomerDisable: false,
            customerNameModel: '',
            customerNameDisable: true,
            customerAddressModel: '',
            customerAddressDisable: true,
            customerCityModel: '',
            customerCityDisable: true,
            customerProvinceModel: '',
            customerProvinceDisable: true,
            longitudeModel: '',
            latitudeModel: '',
            currentLocationModel: '',
            mymap: '',
            showPosition: '',
            checkboxCar: "Y",
            checkboxCars: [
                {id: "Y", desc: "Company Car"},
                {id: "N", desc: "Private Car"},
                {id: "M", desc: "Private Motorcycle"},
                {id: "L", desc: "Online Transport(GrabCar/GoCar/GoJek)"},
            ],
            checkbox1Model: false,
            checkbox2Model: false,
            checkbox3Model: false,
            checkbox4Model: false,
            checkbox5Model: false,
            checkbox6Model: false,
            checkboxLeaveModel: false,
            remarkModel: '',
            newCustSwitchModel: '',
            isLastVisit: false,
            isLastVisitMinutes: '',
            search: null,
            userGroup: null,
            appl_id: 'SALES ACTIVITY',
            imageModel: '',
            salesid: '',
            images: [],
            dialog: false,
            image: '',
            category: '',
            categories: [],
            latitudeBeforeModel: '',
            longitudeBeforeModel: '',
            distance: 0,
            last_visit: {},
            office_data:{},
            price: 0,
            mymap2: '',
            revLocationModel: '',
            speedoKmModel: '',
            speedoKmBeforeModel: '',
            distanceSpeedo: 0,
            checkpointModel: ''
        }
    },
    async mounted(){
        await this.getCategory()
        await this.initialize()
    },
    methods:{
        clear(){
            this.findCustomerModel  = ''
            this.findCustomerLists = []
            this.customerNameModel = ''
            this.customerAddressModel = ''
            this.customerCityModel = ''
            this.customerProvinceModel = ''
            this.latitudeModel = ''
            this.longitudeModel = ''
            this.currentLocationModel = ''
            this.remarkModel = ''
            // this.checkboxCar = "Y"
            this.checkbox1Model = false
            this.checkbox2Model = false
            this.checkbox3Model = false
            this.checkbox4Model = false
            this.checkbox5Model = false
            this.checkbox6Model = false
            this.checkboxLeaveModel = false
            this.images = []
            this.distance = 0
            this.latitudeBeforeModel = ''
            this.longitudeBeforeModel = ''
            $("#fileImage").val(null);
            this.initialize()
        },
        openModal(){
            this.dialog = true
        },
        fileChange(e){
            let file = e.target.files[0]
            console.log(file);
            this.image = ''
            if (!file.type.includes('image/')) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please select an image file!",
                    visible: true
                };
                return false
            }
            // if (file.size > 2000000) {
            //     this.snackbar = {
            //         color: "warning",
            //         icon: "mdi-alert-circle",
            //         mode: "multi-line",
            //         position: "top",
            //         timeout: 7500,
            //         title: "Warning",
            //         text: "File image maximum 2 MB!",
            //         visible: true
            //     };
            //     return false
            // }
            if (typeof FileReader === 'function') {
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.image = event.target.result
                }
                reader.readAsDataURL(file)
            } else {
                this.snackbar = true
                this.text = "Sorry, FileReader API not supported"
                this.color = 'red'
                return
            }            
        },
        hapus(key){
            this.images.splice(key, 1)
        },
        applyCropped(){
            if (this.category && this.image) {
                this.images.push({
                    src: this.image,
                    category: this.category,
                    price: this.price
                })
                this.image = ''
                this.category = ''   
                this.price = 0     
                this.dialog = false
            } else {
                this.dialog = true
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please Fill image and category",
                    visible: true
                };
                return false
            }
            
        },
        async getCategory(){
            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/getCategory`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.categories = res.data
            });
        },
        async initialize(){

            this.$store.dispatch('setOverlay', true)

            // this.getLocation()
            // console.log('myMap begin')
            // console.log(this.mymap)
            if (this.mymap) {
                // console.log('masuk mymap')
                this.mymap.off()
                this.mymap.remove()
                this.mymap = ''
            }

            if (this.mymap2) {
                // console.log('masuk mymap2')
                this.mymap2.off()
                this.mymap2.remove()
                this.mymap2 = ''
            }

            // console.log('myMap after')
            // console.log(this.mymap)

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesid = res.data.salesid
                this.last_visit = res.data.last_visit
                this.office_data = res.data.office
                this.latitudeBeforeModel = this.last_visit ? this.last_visit.sales_latitude : ''
                this.longitudeBeforeModel = this.last_visit ? this.last_visit.sales_longitude : ''
                this.speedoKmBeforeModel = this.last_visit ? parseFloat(this.last_visit.speedometer_km) : 0

                if (this.last_visit) {
                    this.getPrevLocation()
                }
 
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },
        async onChangeCustomer(id)
        {
            if(id == null || id == '' ){

                this.customerNameModel = ''
                this.customerAddressModel = ''
                this.customerCityModel = ''
                this.customerProvinceModel = ''

            } else {

                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/getCustomerDetails?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.customerNameModel = res.data.result.NamaCustomer
                    this.customerAddressModel = res.data.result.Alamat
                    this.customerCityModel = res.data.result.Kota
                    this.customerProvinceModel = res.data.result.Propinsi

                    this.$store.dispatch('setOverlay', false)
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },
        async getCustomer(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/getCustomer`, { 
                    id : id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.findCustomerLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },
        async submitData(){

            // if((this.checkbox6Model == true || this.checkboxLeaveModel == true) && (this.office_data.office_id != 0)){
            //     this.latitudeModel = this.office_data.lat
            //     this.longitudeModel = this.office_data.lng
            //     this.currentLocationModel = this.office_data.address
            // }
            
            if(this.images.length == 0){

                Swal.fire({
                    text: "Please !!! Insert Photo",
                    icon: 'warning',
                })

                return false

            }
            if(this.salesid == '' || this.salesid == null){

                Swal.fire({
                    text: "We're sorry, this application only for Salesman !",
                    icon: 'warning',
                })

                return false

            }
            // if(this.isLastVisit == false){
            if(!this.latitudeModel || !this.longitudeModel){

                Swal.fire({
                    text: 'Please click get current location first !',
                    icon: 'warning',
                })

                return false
            }
            
            this.$store.dispatch('setOverlay', true)
            await axios.post(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/store`, { 
                    customerId: this.findCustomerModel ? this.findCustomerModel : '',
                    custName: this.customerNameModel ? this.customerNameModel : '',
                    custAddress: this.customerAddressModel ? this.customerAddressModel : '',
                    custCity: this.customerCityModel ? this.customerCityModel : '',
                    custProvince: this.customerProvinceModel ? this.customerProvinceModel : '',
                    checkboxCar : this.checkboxCar ? this.checkboxCar : '',
                    checkbox1: this.checkbox1Model ? this.checkbox1Model : '',
                    checkbox2: this.checkbox2Model ? this.checkbox2Model : '',
                    checkbox3: this.checkbox3Model ? this.checkbox3Model : '',
                    checkbox4: this.checkbox4Model ? this.checkbox4Model : '',
                    checkbox5: this.checkbox5Model ? this.checkbox5Model : '',
                    checkbox6: this.checkbox6Model ? this.checkbox6Model : '',
                    checkbox7: this.checkboxLeaveModel ? this.checkboxLeaveModel : '',
                    latitude: this.latitudeModel ? this.latitudeModel : '',
                    longitude: this.longitudeModel ? this.longitudeModel : '',
                    currentLocation: this.currentLocationModel ? this.currentLocationModel : '',
                    remark: this.remarkModel ? this.remarkModel : '',
                    image: this.imageModel ? this.imageModel : '',
                    distance: this.distance ? this.distance : 0,
                    sales_latitude_prev: this.latitudeBeforeModel ? this.latitudeBeforeModel : '',
                    sales_longitude_prev: this.longitudeBeforeModel ? this.longitudeBeforeModel : '',
                    images: this.images,
                    new: this.newCustSwitchModel,
                    speedoKmModel: this.speedoKmModel ? this.speedoKmModel : 0,
                    speedoKmBeforeModel: this.speedoKmBeforeModel ? this.speedoKmBeforeModel : 0,
                    checkpoint: this.checkpointModel ? this.checkpointModel : ''
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                Swal.fire({
                    text: 'Data submitted successfully',
                    icon: 'success',
                })
                this.findCustomerModel  = ''
                this.findCustomerLists = []
                this.customerNameModel = ''
                this.customerAddressModel = ''
                this.customerCityModel = ''
                this.customerProvinceModel = ''
                this.latitudeModel = ''
                this.longitudeModel = ''
                this.currentLocationModel = ''
                this.remarkModel = ''
                this.checkbox1Model = false
                this.checkbox2Model = false
                this.checkbox3Model = false
                this.checkbox4Model = false
                this.checkbox5Model = false
                this.checkbox6Model = false
                this.checkboxLeaveModel = false
                this.images = []
                this.distance = 0
                this.latitudeBeforeModel = ''
                this.longitudeBeforeModel = ''
                this.speedoKmModel = ''
                this.checkpointModel = ''
                $("#fileImage").val(null);
                // this.initialize()
                window.location.reload()

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                Swal.fire({
                    text: err,
                    icon: 'error',
                })
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
            // } else {
            //     Swal.fire({
            //         text: 'Please wait ' + this.isLastVisitMinutes + ' minutes for doing another Customer Visit',
            //         icon: 'warning',
            //     })

            //     return false
            // }
        },

        
        async checkLastVisit(){

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/checkLastVisit`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
                .then(res => {
                    if(res.data.result >= 0){
                        this.isLastVisit = true
                        this.isLastVisitMinutes = res.data.result
                    } else {
                        this.isLastVisit = false
                        this.isLastVisitMinutes = res.data.result
                    }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },
        emptyTab(){

            this.findCustomerModel = ''
            this.findCustomerLists = []

        },        
        async getLocation(){
            
            // if(this.checkboxLeaveModel == true && (this.office_data.office_id != 0)){
            if(this.checkpointModel == 'OS' || this.checkpointModel == 'OE' ){

                this.latitudeModel = this.office_data.lat
                this.longitudeModel = this.office_data.lng
                this.currentLocationModel = this.office_data.address

                this.$store.dispatch('setOverlay', true)

                if (this.mymap != '') {
                    this.mymap.off()
                    this.mymap.remove()
                }

                this.mymap = L.map('mapCanvas').setView([this.latitudeModel, this.longitudeModel], 18);
            
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_STARCON}`, {
                    maxZoom: 18,
                    attribution: '<b>Your current location</b><br> ' + this.currentLocationModel,
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap)

                L.marker([this.latitudeModel, this.longitudeModel],
                    {draggable: false,     
                    //title: 'Hover Text',
                    opacity: 0.75}
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your current location</b><br> ' + this.currentLocationModel)
                    .openPopup()

                if (this.last_visit) {
                    this.calculateDistance()
                    // this.
                }

                this.$store.dispatch('setOverlay', false)

            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.getCurrentLocation)
                } else { 
                    Swal.fire({
                        text: 'Please check GPS and click Allow Request GPS Access !',
                        icon: 'warning',
                    })
                }
            }
            
        },

        async getLocationPrev(){
            if(this.checkboxLeaveModel == true && (this.office_data.office_id != 0)){
                this.latitudeBeforeModel = this.office_data.lat
                this.longitudeBeforeModel = this.office_data.lng
                this.revLocationModel = this.office_data.address

                this.$store.dispatch('setOverlay', true)

                if (this.mymap2 != '') {
                    this.mymap2.off()
                    this.mymap2.remove()
                }

                this.mymap2 = L.map('mapCanvasPrev').setView([this.latitudeBeforeModel, this.longitudeBeforeModel], 18);
            
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_STARCON}`, {
                    maxZoom: 18,
                    attribution: '<b>Your current location</b><br> ' + this.revLocationModel,
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap2)

                L.marker([this.latitudeBeforeModel, this.longitudeBeforeModel],
                    {draggable: false,     
                    //title: 'Hover Text',
                    opacity: 0.75}
                    )
                    .addTo(this.mymap2)
                    .bindPopup('<b>Your current location</b><br> ' + this.revLocationModel)
                    .openPopup()

                if (this.last_visit) {
                    this.calculateDistance()
                    // this.
                }

                this.$store.dispatch('setOverlay', false)

            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.getCurrentLocation)
                } else { 
                    Swal.fire({
                        text: 'Please check GPS and click Allow Request GPS Access !',
                        icon: 'warning',
                    })
                }
            }
            
        },

        async getCurrentLocation(position){

            // if(this.speedoKmModel)
            // {

                this.$store.dispatch('setOverlay', true)

                if (this.mymap != '') {
                    this.mymap.off()
                    this.mymap.remove()
                }

                this.latitudeModel = position.coords.latitude
                this.longitudeModel = position.coords.longitude

                // await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.latitudeModel}&lon=${this.longitudeModel}`, { 
                //     headers: { 
                //         'Accept': 'application/json',
                //         'Content-Type': 'application/json',
                //         // 'Access-Control-Allow-Origin': '*',
                //         Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                //     } 
                //     })
                //     .then(res => {
                //         // console.log(res.data.display_name);
                //         this.currentLocationModel = res.data.display_name
                // })
                // .catch(err => {

                //     console.log(err)

                //     this.$store.dispatch('setOverlay', false)
                //     if(err.response.status == '401'){
                //         this.$router.push('/');
                //     }

                // })

                await axios.get(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/getAddress?lat=${this.latitudeModel}&lon=${this.longitudeModel}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.currentLocationModel = res.data.features[0].place_name

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

                this.mymap = L.map('mapCanvas').setView([this.latitudeModel, this.longitudeModel], 18);
            
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_STARCON}`, {
                    maxZoom: 18,
                    attribution: '<b>Your current location</b><br> ' + this.currentLocationModel,
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap)

                L.marker([this.latitudeModel, this.longitudeModel],
                    {draggable: false,     
                    //title: 'Hover Text',
                    opacity: 0.75}
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your current location</b><br> ' + this.currentLocationModel)
                    .openPopup()

                if (this.last_visit) {
                    this.calculateDistance()
                    // this.
                }

                this.$store.dispatch('setOverlay', false)

            // } else {
            //     this.$refs.speedoInput.focus()
            //     Swal.fire({
            //         text: 'Please fill Speedometer KM !',
            //         icon: 'warning',
            //     })
            // }

        },
        async getPrevLocation(){
            var mymap = L.map('mapCanvasPrev').setView([this.latitudeBeforeModel, this.longitudeBeforeModel], 18);
        
            L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_STARCON}`, {
                maxZoom: 18,
                attribution: '<b>Your current location</b><br> ' + this.last_visit.lt_location,
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
            }).addTo(mymap)

            L.marker([this.latitudeBeforeModel, this.longitudeBeforeModel],
                {draggable: false,     
                //title: 'Hover Text',
                opacity: 0.75}
                )
                .addTo(mymap)
                .bindPopup('<b>Your prev location</b><br> ' + this.last_visit.lt_location)
                .openPopup()
        },
        async calculateDistance(){

                await axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${this.longitudeBeforeModel}%2C${this.latitudeBeforeModel}%3B${this.longitudeModel}%2C${this.latitudeModel}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=${process.env.TK_STARCON}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        // Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    if (res.data.code.toLowerCase() === 'ok') {
                        if (res.data.routes.length > 0) {
                            var distance = Number(res.data.routes[0].distance) / 1000
                            this.distance = distance.toFixed([2])
                            this.distanceSpeedo = this.speedoKmModel - this.speedoKmBeforeModel
                        } else {
                            this.distance = 0
                            this.distanceSpeedo = 0
                        }
                    } else {
                        this.distance = 0
                        this.distanceSpeedo = 0
                    }
                })
                .catch(err => {
                    this.distance = 0
                    console.log(err.response);
                })
            
        },
        hideSearhCustomer(){

            if(this.newCustSwitchModel == 'Y'){

                this.findCustomerModel  = ''
                this.findCustomerLists = []
                this.showFindCustomerDisable = true
                this.customerNameModel = ''
                this.customerNameDisable = false
                this.customerAddressModel = ''
                this.customerAddressDisable = false
                this.customerCityModel = ''
                this.customerCityDisable = false
                this.customerProvinceModel = ''
                this.customerProvinceDisable = false
            } else {
                this.showFindCustomerDisable = false
                this.customerNameModel = ''
                this.customerNameDisable = true
                this.customerAddressModel = ''
                this.customerAddressDisable = true
                this.customerCityModel = ''
                this.customerCityDisable = true
                this.customerProvinceModel = ''
                this.customerProvinceDisable = true
            }

        },
        fileImageChange(e){

            let file = e.target.files[0]
            this.imageModel = ''

            if(file){

                if (!file.type.includes('image/')) {
                    swal("Error", "Please select an image file!", "error");
                    return
                }
                // if (file.size > 10000000) {
                //     swal("Failed", "File image maximum 10 MB!", "error");
                //     return
                // }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        this.imageModel = event.target.result
                    }
                    reader.readAsDataURL(file)
                } else {
                    swal("Error", "Sorry, FileReader API not supported", "error");
                }

            }

        },
    },
    watch: {
        search (val) {
            val && val !== this.findCustomerModel && this.getCustomer(val)
        },

        checkbox6Model(newValue) {
            console.log("checkbox6Model changed to:", newValue);
        }
        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .vertical-align {
        align-items: center;
    }

</style>